import React from "react";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelLink,
  GelColumnLayout,
  GelParagraph,
} from "@tal-gel/components";
import brandLogo from "../../assets/images/tal-logo.svg";
import { getGelTokens } from "@tal-gel/theming";

interface Props {
  contentfulData: any;
}

const Footer: React.FC<Props> = ({ contentfulData }) => {
  return (
    <GelContainerLite>
      <GelBoxLayout
        vertical={true}
        alignment="center"
        marginBottom={`${getGelTokens().global.sizeBaseX16}px`}
      >
        <img
          id="brand-logo"
          alt="logo"
          src={brandLogo}
          style={{
            display: "block",
            margin: "auto",
            marginTop: `${getGelTokens().global.sizeBaseX16}px`,
          }}
        />
        <div>
          <GelLink
            style={{ marginRight: getGelTokens().global.sizeBaseX2 }}
            href={
              contentfulData?.mmcEftPageCollection.items[0]?.insurerPrivacyLink
            }
          >
            Insurer Privacy{" "}
          </GelLink>{" "}
          |{" "}
          <GelLink
            style={{ marginLeft: getGelTokens().global.sizeBaseX2 }}
            href={
              contentfulData?.mmcEftPageCollection.items[0]
                ?.insurerDisclaimerLink
            }
          >
            {" "}
            Insurer Disclaimer
          </GelLink>
        </div>
        <GelParagraph style={{ textAlign: "center" }}>
          {contentfulData?.mmcEftPageCollection.items[0]?.footerText1}
          <br />
          {contentfulData?.mmcEftPageCollection.items[0]?.footerText2}
        </GelParagraph>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default Footer;
