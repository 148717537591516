import { useOktaAuth } from "@okta/okta-react";
import axios, { HttpStatusCode } from "axios";
import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { CookieConfig } from "../constants/constants";

const HttpClientService = (props: { children?: any }) => {
  const [ssoCookies, setSsoCookies, removeSsoCookies] = useCookies([
    "aid",
    "rid",
    "ssoid",
    "tpid",
  ]);
  const navigate = useNavigate();

  const clearSession = () => {
    window.sessionStorage.clear();

    setSsoCookies("aid", "", {
      domain: CookieConfig.DOMAIN,
      path: CookieConfig.PATH,
      secure: true,
      sameSite: "lax",
      expires: new Date(new Date().getTime()),
    });
    setSsoCookies("rid", "", {
      domain: CookieConfig.DOMAIN,
      path: CookieConfig.PATH,
      secure: true,
      sameSite: "lax",
      expires: new Date(new Date().getTime()),
    });
  };

  useEffect(() => {
    axios.interceptors.request.use(
      (request) => {
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.headers["x-aid"] && response.headers["x-rid"]) {
          removeSsoCookies("aid", {
            domain: CookieConfig.domain,
            path: CookieConfig.path,
          });
          removeSsoCookies("rid", {
            domain: CookieConfig.domain,
            path: CookieConfig.path,
          });

          setSsoCookies("aid", response.headers["x-aid"], {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH,
            secure: true,
            sameSite: "lax",
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          });
          setSsoCookies("rid", response.headers["x-rid"], {
            domain: CookieConfig.DOMAIN,
            path: CookieConfig.PATH,
            secure: true,
            sameSite: "lax",
            expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          });
        }

        if (
          response.data.errors &&
          response.data.errors.length > 0 &&
          response.data.errors[0].extensions.HttpStatusCode ==
            HttpStatusCode.Forbidden
        ) {
          clearSession();
          navigate("/error", {
            state: { errorCode: HttpStatusCode.Forbidden },
          });
        }
        return response;
      },
      async (error) => {
        console.log("error", error.response.status);
        if (error.response.status === HttpStatusCode.Unauthorized) {
          clearSession();
          navigate("/error", {
            state: { errorCode: HttpStatusCode.Unauthorized },
          });
        }

        return Promise.reject(error);
      }
    );
  });

  return props.children;
};

export default HttpClientService;
