import ErrorPage from "./pages/errorPage";
import SuccessPage from "./pages/successPage";
import ProtectedRoute from "./authentication/secure.route";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import VerificationPage from "./pages/verificationPage";
import EftForm from "./pages/eftForm";
import HttpClientService from "./api/http-client.provider";
import AutomationPage from "./pages/automationPage";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <HttpClientService>
          <Routes>
            <Route path="/" element={<Navigate to="/verify-passcode" />} />

            <Route element={<ProtectedRoute />}>
              <Route path="/verify-passcode" element={<VerificationPage />} />
              <Route path="/automation-login" element={<AutomationPage />} />

              <Route path="/eft-form" element={<EftForm />} />
              <Route path="/success" element={<SuccessPage />} />
            </Route>
            <Route path="/error" element={<ErrorPage />} />
          </Routes>
        </HttpClientService>
      </HashRouter>
    </div>
  );
}

export default App;
