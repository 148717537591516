const SESSION_STORAGE_KEY = {
  X_REQUEST_ID: "X-Request_Id",
  APIM_KEY: "APIM_Key",
  ACCOUNT_TYPE: "accountType",
  IS_UMBRELLAFUND: "Is_UmbrellaFund",
  THEME: "theme",
  CLAIM_ID: "claimId",
  REQUIREMENT_ID: "requirementId",
};

export default SESSION_STORAGE_KEY;
