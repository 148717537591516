import React from "react";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelLink,
  GelHeading3,
} from "@tal-gel/components";
import brandLogo from "../../assets/images/tal-logo.svg";
import { getGelTokens } from "@tal-gel/theming";
import heroImage from "../../assets/images/background.jpg";

export interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <div>
      <GelContainerLite
        gutter="small"
        style={{ backgroundColor: getGelTokens().global.themeColorWhite }}
      >
        <GelBoxLayout gap="large" distribution="end" marginLeft="medium">
          <div
            style={{
              float: "left",
              height: getGelTokens().global.sizeBaseX15,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              id="brand-logo"
              alt="logo"
              src={brandLogo}
              style={{ display: "block", margin: "auto", cursor: "pointer" }}
            />
          </div>
        </GelBoxLayout>
      </GelContainerLite>
      <div
        style={{
          alignItems: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: `${getGelTokens().global.sizeBaseX15 * 3}px`,
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            WebkitBoxAlign: "center",
          }}
        >
          <GelHeading3 marginLeft="large" inverse="true">
            {props.title}
          </GelHeading3>
        </div>
      </div>
    </div>
  );
};

export default Header;
