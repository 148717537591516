import { configureTheming } from '@tal-gel/theming';
import ThemingPluginTal from '@tal-gel/theming-plugin-tal';
import ThemingPluginAware from '@tal-gel/theming-plugin-aware';
import ThemingPluginVicSuper from '@tal-gel/theming-plugin-vicsuper';
import ThemingPluginAusSuper from '@tal-gel/theming-plugin-aussuper';
import ThemingPluginCbus from '@tal-gel/theming-plugin-cbus';
import ThemingPluginAmp from '@tal-gel/theming-plugin-amp';

configureTheming({
  plugins: [
    ThemingPluginTal,
    ThemingPluginAware,
    ThemingPluginVicSuper,
    ThemingPluginAusSuper,
    ThemingPluginCbus,
    ThemingPluginAmp
  ]
});