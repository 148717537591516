export const VALIDATE_MFA_QUERY = `mutation ValidateMfa($passCodeValidationRequest: PassCodeValidationRequestInput!) {
    validateMfa(passCodeValidationRequest: $passCodeValidationRequest) {
        isSuccess
   }
  }`;

export const GET_BANK_NAME_QUERY = `query bankNameForBsbNumber($bsbNumber: String!) {
    bankNameForBsbNumber(bsbNumber: $bsbNumber) {
      bankName
    }
  }`;

export const SUBMIT_CLAIM_PAYMENT_FORM_QUERY = `mutation SubmitClaimPaymentForm($claimPaymentRequest: ClaimPaymentRequestInput!) {
    submitClaimPaymentForm(claimPaymentRequest: $claimPaymentRequest) {
      response
   },
  }`;

export const RESEND_MFA = `mutation ResendMfa($mfaConfigRequest : MfaConfigRequestInput!)
    {
        resendMfa(mfaConfigRequest: $mfaConfigRequest)
        {
            isSuccess
        }
     }`;
