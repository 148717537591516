import { GelThemeProvider, useThemeSelector } from "@tal-gel/theming";
import { GelScreenDetector } from "@tal-gel/components";
import React, { useContext } from "react";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { ThemeContext } from "./authentication/themes.context";
import { HOST_BRAND_MAP } from "./constants/constants";
import SESSION_STORAGE_KEY from "./constants/storage.constant";

const GelThemes = () => {
  const { themeData } = useContext(ThemeContext);

  function getAppTheme() {
    let sessionTheme = window.sessionStorage.getItem(SESSION_STORAGE_KEY.THEME);

    return sessionTheme
      ? HOST_BRAND_MAP[sessionTheme]
      : themeData?.theme
      ? HOST_BRAND_MAP[themeData.theme]
      : "tal";
  }

  return (
    <GelThemeProvider theme={getAppTheme()}>
      <GelScreenDetector>
        <App />
      </GelScreenDetector>
    </GelThemeProvider>
  );
};

export default GelThemes;
