import Storage from "../common/storage.common";
import SESSION_STORAGE_KEY from "../constants/storage.constant";

let API_BASE = "";
let CONTENTFUL_URL = "";
let Cookie_Domain = "";

if (process.env.NODE_ENV === "production") {
  API_BASE = "#{GRAPHQL_ENDPOINT}/graphql";
  //For production build set Ocp-Apim-Subscription-Key
  CONTENTFUL_URL = "#{CMS_API_URL}";
  Storage.setItem(SESSION_STORAGE_KEY.APIM_KEY, "#{APIM-KEY}");
  Cookie_Domain = ".urlife.net.au";
} else {
  API_BASE = "https://localhost:7065/graphql";
  CONTENTFUL_URL =
    "https://graphql.contentful.com/content/v1/spaces/9p83ob5n4kp5/environments/master?access_token=HaQT2lub60IFqqX896_XGCrVHRExaNG9pNFAJDHszYA";
  Cookie_Domain = "localhost";
}

const API = {
  BASEURL: API_BASE,
  CONTENTFUL_URL: CONTENTFUL_URL,
};

export default API;

export const THEME_NAME: { [name: string]: string } = {
  TAL: "tal",
  AWARE: "aware",
  VICSUPER: "vicSuper",
  CBUS: "cbus",
  AUSSUPER: "ausSuper",
  AMP: "amp",
};

export const HOST_BRAND_MAP: { [name: string]: string } = {
  tal: THEME_NAME.TAL,
  aware: THEME_NAME.AWARE,
  vicsuper: THEME_NAME.VICSUPER,
  cbus: THEME_NAME.CBUS,
  aussuper: THEME_NAME.AUSSUPER,
  amp: THEME_NAME.AMP,
};

export const CookieConfig: { [name: string]: string } = {
  DOMAIN: Cookie_Domain,
  PATH: "/",
  SAMESITE: "lax",
};
