import React, { useState, useEffect } from "react";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelParagraph,
  GelLabel,
  GelForm,
  GelFormField,
  GelNumberInput,
  GelTextInput,
  useGelFormData,
  GelSpinner,
  GelCaption,
} from "@tal-gel/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "../constants/constants";
import { useCookies } from "react-cookie";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getGelTokens } from "@tal-gel/theming";
import { VALIDATE_MFA_QUERY, RESEND_MFA } from "../graphql/graphql-bff";
import { GET_EFTPAGE_CONTENT_QUERY } from "../graphql/graphql-contentful";
import Header from "./common/header";
import Footer from "./common/footer";

const AutomationPage = () => {
  const [contentfulData, setcontenfulData] = useState<null | {
    mmcEftPageCollection: any;
  }>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [ssoCookies] = useCookies(["aid", "rid", "ssoid", "tpid", "sid"]);

  const { formData, onFormDataChange, setFormData } = useGelFormData({
    username: "",
    password: "",
  });

  const onSubmit = () => {
    if (formData.username !== "" && formData.password !== "") {
      navigate("/eft-form", {
        state: {
          auth: true,
        },
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);

    //contentful call
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_EFTPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });

    const queryParams = new URLSearchParams(window.location.href);
    const origin = queryParams.get("origin");

    const pattern = /info=([^&]+)/;
    const match = window.location.href.match(pattern);
    const info = match ? match[1] : "";
    const params = info.split("_");

    const claimId = params[1];
    const requirementId = params[2];

    if (claimId && requirementId && origin) {
      sessionStorage.setItem("claimId", claimId);
      sessionStorage.setItem("requirementId", requirementId);
      sessionStorage.setItem("origin", origin);
    }

    setIsLoading(false);
  }, []);

  return (
    <div>
      <Header title="Automation Login" />
      <GelContainerLite>
        {isLoading && <GelSpinner large overlay />}
        <GelBoxLayout
          gap="medium"
          alignment="start"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          padding="large"
          margin="large"
          style={{
            marginTop: `-${getGelTokens().global.sizeBaseX15}px`,
            backgroundColor: getGelTokens().global.themeColorWhite,
            border: `${getGelTokens().global.borderSizeSm}px solid`,
            borderColor: getGelTokens().global.themeColorGrayT20,
          }}
        >
          <GelForm labelAtTop onSubmit={onSubmit}>
            <GelFormField label="Username">
              <GelTextInput
                name="username"
                defaultValue={formData.username}
                onChange={onFormDataChange}
                noFormat
                required
              />
            </GelFormField>
            <GelFormField label="Password">
              <GelTextInput
                name="password"
                defaultValue={formData.passcode}
                onChange={onFormDataChange}
                required
              />
            </GelFormField>

            <GelBoxLayout alignment="start" gap="small">
              <GelFormField>
                <GelButton
                  name="" // Makes it a managed component
                  primary
                  medium
                  submit
                  onSubmit={onSubmit}
                >
                  {
                    contentfulData?.mmcEftPageCollection.items[0]
                      ?.mfaSubmitButtonText
                  }
                </GelButton>
              </GelFormField>
            </GelBoxLayout>
          </GelForm>
        </GelBoxLayout>
      </GelContainerLite>
      <Footer contentfulData={contentfulData} />
    </div>
  );
};

export default AutomationPage;
