import React, { useState, useEffect } from "react";
import {
  GelForm,
  GelFormField,
  GelTextInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelContainerLite,
  GelNumberInput,
  GelModal,
  GelParagraph,
  GelHeading4,
  GelSpinner,
  GelCaption,
  GelLabel,
  GelTooltip,
} from "@tal-gel/components";
import { useNavigate, useLocation } from "react-router-dom";
import { getGelTokens } from "@tal-gel/theming";
import axios, { AxiosResponse } from "axios";
import API from "../constants/constants";
import { useCookies } from "react-cookie";
import Header from "./common/header";
import Footer from "./common/footer";
import {
  SUBMIT_CLAIM_PAYMENT_FORM_QUERY,
  GET_BANK_NAME_QUERY,
} from "../graphql/graphql-bff";

import { GET_EFTPAGE_CONTENT_QUERY } from "../graphql/graphql-contentful";

const EftForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const claimId = window.sessionStorage.getItem("claimId") ?? "";
  const requirementId = window.sessionStorage.getItem("requirementId") ?? "";

  const [openModal, setOpenModal] = useState(false);
  const [bsbName, setBsbName] = useState("");
  const [bsbResult, setBsbResult] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isBsbError, setIsBsbError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contentfulData, setcontenfulData] = useState<null | {
    mmcEftPageCollection: any;
  }>(null);
  const origin = sessionStorage.getItem("origin") ?? "";
  const [ssoCookies] = useCookies(["aid", "rid", "ssoid", "tpid", "sid"]);

  const { formData, onFormDataChange, setFormData } = useGelFormData({
    accountName: "",
    bsb: "",
    accountNumber: "",
  });

  useEffect(() => {
    if (!state) {
      sessionStorage.removeItem("origin");
      window.location.replace(atob(origin));
    }

    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_EFTPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });
  }, []);

  //ideally this would not be needed and preventSubmitOnInvalid prop on the GelForm should be used
  //however the prop isnt behaving as documented so working around it for now
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const bsbValidation = !isBsbError && formData.bsb !== "";

    if (e.target.value !== "") {
      if (
        e.target.name === "accountName" &&
        bsbValidation &&
        formData.accountNumber !== ""
      ) {
        setIsDisabled(false);
      } else if (
        e.target.name === "accountNumber" &&
        bsbValidation &&
        formData.accountName !== ""
      ) {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  };

  const onChangeBsb = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setBsbResult(false);
    if (e.target.value === "") {
      //using required prop on input component so removing custom error here
      setIsBsbError(false);
      setErrorMessage("");
      setIsDisabled(true);
    } else if (e.target.value.length < 6) {
      setIsBsbError(true);
      setErrorMessage(
        contentfulData?.mmcEftPageCollection.items[0]?.errorMessageContent
          .bsbMinLength
      );
      setIsDisabled(true);
    } else {
      setIsLoading(true);
      setIsBsbError(false);

      const variables = {
        bsbNumber: e.target.value,
      };

      axios
        .post(
          API.BASEURL,
          {
            query: GET_BANK_NAME_QUERY,
            variables: variables,
          },
          {
            headers: {
              "x-aid": ssoCookies.aid,
              "x-rid": ssoCookies.rid,
              ssoid: ssoCookies.ssoid,
              tpid: ssoCookies.tpid,
              sid: ssoCookies.sid,
            },
          }
        )
        .then((response: AxiosResponse<any>) => {
          setIsLoading(false);

          if (response.data.data.bankNameForBsbNumber.bankName === "") {
            setIsBsbError(true);
            setErrorMessage(
              contentfulData?.mmcEftPageCollection.items[0]?.errorMessageContent
                .bsbInvalid
            );
          } else {
            setBsbResult(true);
            setBsbName(response.data.data.bankNameForBsbNumber.bankName);
            if (formData.accountName !== "" && formData.accountNumber !== "") {
              setIsDisabled(false);
            }
          }
        })
        .catch((error: any) => {
          setIsLoading(false);
          navigate("/error");
        });
    }
  };

  const onClickCancel = () => {
    setOpenModal(true);
  };

  const onClickCancelConfirm = () => {
    const origin = sessionStorage.getItem("origin") ?? "";

    if (atob(origin).indexOf("?source=") > 0) {
      window.location.replace(atob(origin));
    } else {
      sessionStorage.removeItem("origin");
      window.location.replace(atob(origin) + "?source=EFT");
    }
  };

  const onSubmit = () => {
    //show spinner and text
    setIsWaiting(true);
    //make the api call

    const variables = {
      claimPaymentRequest: {
        accountName: formData.accountName,
        accountNumber: formData.accountNumber,
        bsbNumber: formData.bsb,
        claimId: parseInt(claimId),
        requirementId: parseInt(requirementId),
      },
    };

    axios
      .post(
        API.BASEURL,
        {
          query: SUBMIT_CLAIM_PAYMENT_FORM_QUERY,
          variables: variables,
        },
        {
          headers: {
            "x-aid": ssoCookies.aid,
            "x-rid": ssoCookies.rid,
            ssoid: ssoCookies.ssoid,
            tpid: ssoCookies.tpid,
            sid: ssoCookies.sid,
          },
          timeout: 600000,
        }
      )
      .then((response: AxiosResponse<any>) => {
        navigate("/success", {
          state: {
            auth: true,
          },
        });
      })
      .catch((error: any) => {
        navigate("/error");
      });
  };

  return (
    <div>
      <Header
        title={contentfulData?.mmcEftPageCollection.items[0]?.eftPageTitle}
      />
      <GelContainerLite>
        <GelBoxLayout
          gap="medium"
          alignment="start"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          paddingTop="large"
          padding="medium"
          margin="large"
          style={{
            marginTop: `-${getGelTokens().global.sizeBaseX15}px`,
            backgroundColor: getGelTokens().global.themeColorWhite,
            border: `${getGelTokens().global.borderSizeSm}px solid`,
            borderColor: getGelTokens().global.themeColorGrayT20,
          }}
        >
          <GelParagraph textAlign="left">
            {contentfulData?.mmcEftPageCollection.items[0]?.eftPageDescription}
          </GelParagraph>
          <GelHeading4 textAlign="left">
            {contentfulData?.mmcEftPageCollection.items[0]?.eftFormTitle}
          </GelHeading4>
          <GelContainerLite gutter="small">
            <GelBoxLayout
              gap="medium"
              marginBottom="small"
              width={`${getGelTokens().global.gridSidenavWidth}px`}
            >
              <GelForm
                labelAtTop
                disableOnSubmit
                onSubmit={onSubmit}
                style={{ marginRight: getGelTokens().global.sizeBaseX4 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: getGelTokens().global.sizeBaseX2,
                  }}
                >
                  <GelLabel marginRight="small">
                    {
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.eftAccountNameLabel
                    }
                  </GelLabel>
                  <GelTooltip right maxWidth={getGelTokens().global.sizeBase15}>
                    {
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.eftAccountNameTooltipText
                    }
                  </GelTooltip>
                </div>
                <GelFormField>
                  <GelTextInput
                    name="accountName"
                    defaultValue={formData.accountName}
                    onChange={onChange}
                    required
                    requiredErrorMsg={
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.errorMessageContent.required
                    }
                  />
                </GelFormField>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: getGelTokens().global.sizeBaseX2,
                  }}
                >
                  <GelLabel marginRight="small">
                    {contentfulData?.mmcEftPageCollection.items[0]?.eftBsbLabel}
                  </GelLabel>
                </div>
                <GelFormField>
                  <GelNumberInput
                    name="bsb"
                    value={formData.bsb}
                    onChange={onChangeBsb}
                    error={isBsbError}
                    noFormat
                    integer
                    maxLength="6"
                    required
                    requiredErrorMsg={
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.errorMessageContent.required
                    }
                  />
                  {isLoading && (
                    <GelBoxLayout
                      align="center"
                      marginTop="small"
                      marginBottom="small"
                    >
                      <GelSpinner small />
                    </GelBoxLayout>
                  )}
                  {bsbResult && (
                    <GelBoxLayout marginTop="small">
                      <GelParagraph>{bsbName}</GelParagraph>
                    </GelBoxLayout>
                  )}
                  {isBsbError && (
                    <GelBoxLayout marginTop="small">
                      <GelCaption
                        condensed
                        style={{
                          color: getGelTokens().global.themeColorDanger,
                        }}
                      >
                        {errorMessage}
                      </GelCaption>
                    </GelBoxLayout>
                  )}
                </GelFormField>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: getGelTokens().global.sizeBaseX2,
                  }}
                >
                  <GelLabel marginRight="small">
                    {
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.eftAccountNumberLabel
                    }
                  </GelLabel>
                </div>
                <GelFormField>
                  <GelNumberInput
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={onChange}
                    required
                    requiredErrorMsg={
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.errorMessageContent.required
                    }
                    noFormat
                    integer
                  />
                </GelFormField>
                <GelBoxLayout alignment="start" gap="small">
                  <GelFormField>
                    <GelButton
                      name="" // Makes it a managed component
                      primary
                      medium
                      submit
                      disabled={isDisabled}
                    >
                      {
                        contentfulData?.mmcEftPageCollection.items[0]
                          ?.eftSubmitButtonText
                      }
                    </GelButton>
                  </GelFormField>
                  <GelFormField>
                    <GelButton
                      name="" // Makes it a managed component
                      secondary
                      medium
                      onClick={onClickCancel}
                    >
                      {
                        contentfulData?.mmcEftPageCollection.items[0]
                          ?.eftCancelButtonText
                      }
                    </GelButton>
                  </GelFormField>
                </GelBoxLayout>
              </GelForm>
            </GelBoxLayout>
            <GelBoxLayout>
              {isWaiting && (
                <div style={{ display: "flex" }}>
                  <GelSpinner small left />
                  <GelParagraph paddingLeft="large">
                    Please wait while the form is being submitted
                  </GelParagraph>
                </div>
              )}
            </GelBoxLayout>
            <GelModal
              width="sm"
              open={openModal}
              onClose={() => setOpenModal(false)}
              title={
                contentfulData?.mmcEftPageCollection.items[0]?.eftModalTitle
              }
              sticky
              action={
                <GelBoxLayout gap="small" space="auto">
                  <GelButton
                    secondary
                    medium
                    width={getGelTokens().global.sizeBaseUnit * 22}
                    onClick={() => setOpenModal(false)}
                  >
                    {
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.eftModalCancelButtonText
                    }
                  </GelButton>

                  <GelButton
                    primary
                    medium
                    width={getGelTokens().global.sizeBaseUnit * 22}
                    onClick={onClickCancelConfirm}
                  >
                    {
                      contentfulData?.mmcEftPageCollection.items[0]
                        ?.eftModalConfirmButtonText
                    }
                  </GelButton>
                </GelBoxLayout>
              }
            >
              <GelParagraph>
                {contentfulData?.mmcEftPageCollection.items[0]?.eftModalText}
              </GelParagraph>
              <GelParagraph>
                {" "}
                {contentfulData?.mmcEftPageCollection.items[0]?.eftModalText2}
              </GelParagraph>
            </GelModal>
          </GelContainerLite>
          <GelParagraph textAlign="left">
            {
              contentfulData?.mmcEftPageCollection.items[0]
                ?.eftFormDisclaimerText
            }
          </GelParagraph>
        </GelBoxLayout>
      </GelContainerLite>
      <Footer contentfulData={contentfulData} />
    </div>
  );
};

export default EftForm;
