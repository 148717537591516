export const GET_EFTPAGE_CONTENT_QUERY = `
query GetMmcEftPageItemsCollectionForFund($fundName: [String]) {
  mmcEftPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      mfaPageTitle
      errorMessageContent
      mfaDescription
      mfaFormLabel
      mfaResendButtonText
      mfaSubmitButtonText
      eftPageTitle
      eftPageDescription
      eftFormTitle
      eftAccountNameLabel
      eftAccountNameTooltipText
      eftBsbLabel
      eftAccountNumberLabel
      eftSubmitButtonText
      eftCancelButtonText
      eftDisclaimerText
      eftFormDisclaimerText
      eftModalTitle
      eftModalText
      eftModalText2
      eftModalCancelButtonText
      eftModalConfirmButtonText
      insurerPrivacyLink
      insurerDisclaimerLink
      footerText1
      footerText2
    }
  }
}
`;

export const GET_SUCCESSPAGE_CONTENT_QUERY = `
query GetMmcEftPageItemsCollectionForFund($fundName: [String]) {
  mmcEftPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      successPageTitle
      successPageText1
      successPageText2
      insurerPrivacyLink
      insurerDisclaimerLink
      footerText1
      footerText2
    }
  }
}
`;

export const GET_ERRORPAGE_CONTENT_QUERY = `
query GetMmcEftPageItemsCollectionForFund($fundName: [String]) {
  mmcEftPageCollection(where: {contentfulMetadata: {tags: {id_contains_all: $fundName}}}) {
    items {
      errorPageTitle
      errorPageText1
      errorPageText2
      insurerPrivacyLink
      insurerDisclaimerLink
      footerText1
      footerText2
    }
  }
}
`;
