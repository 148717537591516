class Storage {
  setItem(itemKey: any, itemVal: any) {
    sessionStorage.setItem(itemKey, itemVal);
  }

  getItem(itemKey: any) {
    return sessionStorage.getItem(itemKey);
  }

  removeItem(itemKey: any) {
    sessionStorage.removeItem(itemKey);
  }
}

export default new Storage();
