import { Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import ErrorPage from "../pages/errorPage";
import { useOktaAuth } from "@okta/okta-react";

function ProtectedRoute() {
  const [ssoCookies] = useCookies(["aid", "rid"]);

  if (ssoCookies.aid && ssoCookies.rid) return <Outlet />;
  else return <ErrorPage />;
}

export default ProtectedRoute;
