import React, { useState, useEffect } from "react";
import Header from "./common/header";
import { GelHeading3, GelDisplay4 } from "@tal-gel/components";
import { GelBoxLayout, GelContainerLite, GelLabel } from "@tal-gel/components";
import Footer from "./common/footer";
import { faFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { GET_ERRORPAGE_CONTENT_QUERY } from "../graphql/graphql-contentful";
import API from "../constants/constants";

import { getGelTokens } from "@tal-gel/theming";

const ErrorPage = () => {
  const [contentfulData, setcontenfulData] = useState<null | {
    mmcEftPageCollection: any;
  }>(null);

  useEffect(() => {
    //contentful call
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_ERRORPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });
  }, []);
  return (
    <div
      style={{
        backgroundColor: getGelTokens().global.themeColorGrayT05,
      }}
    >
      <Header
        title={contentfulData?.mmcEftPageCollection.items[0]?.errorPageTitle}
      />
      <GelContainerLite>
        <GelBoxLayout
          gap="medium"
          alignment="center"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          padding="large"
          margin="large"
          style={{
            marginTop: `-${getGelTokens().global.sizeBaseX15}px`,
            backgroundColor: getGelTokens().global.themeColorWhite,
            border: `${getGelTokens().global.borderSizeSm}px solid`,
            borderColor: getGelTokens().global.themeColorGrayT20,
          }}
        >
          <FontAwesomeIcon
            icon={faFrown}
            style={{
              fontSize: `${getGelTokens().global.sizeBaseUnit}em`,
              color: getGelTokens().global.themeColorBackgroundDanger,
            }}
          />
          <GelLabel large>
            {contentfulData?.mmcEftPageCollection.items[0]?.errorPageText1}
          </GelLabel>
          <GelLabel large>
            {contentfulData?.mmcEftPageCollection.items[0]?.errorPageText2}
          </GelLabel>
        </GelBoxLayout>
      </GelContainerLite>
      <Footer contentfulData={contentfulData} />
    </div>
  );
};

export default ErrorPage;
